<template>
  <div class="columns is-centered">
    <div class="column is-one-third m-2">
      <h3>Straßenverzeichnis</h3>
      <div class="box">
        <b-autocomplete
          class="is-primary"
          rounded
          v-model="name"
          :data="filteredDataArray"
          placeholder="z.B. Jülicher Straße"
          icon="magnify"
          clearable
          @select="option => handleSelect(option)"
        >
          <template #empty>No results found</template>
        </b-autocomplete>
      </div>
      <div v-if="details && details.length" class="box">
        <p class="is-size-6">Ergebnis für: {{selected}}</p>
        <div class="m-2"></div>
        <ul>
            <li v-for="item in details" :key="item + Math.random()" class="mt-2 p-2">
                {{ item }}
            </li>
            <!-- <li class="mt-2 p-2">Es wird gerade noch geklärt, ob die App verwendet werden darf. Die Straßeninfos wurden vorerst rausgenommen.</li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "StreetSearch",
  data() {
    return {
      name: "",
      selected: null,
      details: "",
      data: []
    }
  },
  computed: {
    filteredDataArray: function() {
        return Object.keys(this.data).filter(option => {
            return (
                option
                    .toString()
                    .toLowerCase()
                    .indexOf(this.name.toLowerCase()) >= 0
              );
        });
    }
  },
  methods: {
      handleSelect: function(option) {
          this.selected = option;
          this.details = this.data[option];
      }
  },
  mounted: function() {
    this.$root.$on('dataUpdated', () => {
      this.data = JSON.parse(localStorage.getItem('data'));
    });
    if(localStorage.getItem('data') != null) {
      this.data = JSON.parse(localStorage.getItem('data'));
    }
  }
};
</script>

<style lang="scss">
    li {
        color: orange;
        // border-bottom: 1px solid green;
        background-color: black;
    }
</style>