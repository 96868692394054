<template>
    <b-navbar>
        <template #brand>
            <b-navbar-item>
                <div class="control">
                    <b-button 
                        :disabled="!!disableUpdateButton" 
                        type="is-info" 
                        size="is-small" 
                        v-on:click="update" 
                        v-if="dupdateAvailable">
                            Update laden 
                            <b-icon
                                class="ml-2"
                                pack="fas"
                                icon="sync-alt"
                                custom-class="fa-spin"
                                v-if="updating"
                                >
                            </b-icon>
                    </b-button>
                    <b-taglist attached v-else>
                        <b-tag type="is-dark">Version</b-tag>
                        <b-tag type="is-success">
                            <p v-if="versionIsLoading">
                                <b-icon
                                    pack="fas"
                                    icon="sync-alt"
                                    custom-class="fa-spin">
                                </b-icon>
                            </p>
                            <p v-else>
                                {{ dversion }}
                            </p>
                        </b-tag>
                    </b-taglist>
                </div>

            </b-navbar-item>
        </template>
        <template #start>
            <b-navbar-item href="/">
                Home
            </b-navbar-item>
            <b-navbar-item href="/about">
                Kontakt
            </b-navbar-item>
            <b-navbar-item href="/impressum">
                Impressum
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

import API from '@/api/API';

export default {
  name: 'NavigationBar',
  props: ['version', 'versionIsLoading', 'updateAvailable'],
  data() {
      return {
        disableUpdateButton: false,
        updating: false,
        dversion: this.version,
        dupdateAvailable: this.updateAvailable,
    }
  },
  methods: {
      update: function() {
          this.disableUpdateButton = true;
          this.updating = true;
          API.getVersion().then(v => {
            API.getData().then(x => {
                localStorage.setItem('version', v.data.version);
                localStorage.setItem('data', JSON.stringify(x.data.data))
                this.dversion = v.data.version;
                this.updating = false;
                this.dupdateAvailable = false;
                this.$root.$emit('dataUpdated', 'new data');
            })
          })
      }
  },
  watch: {
      version: function() {
          this.dversion = this.version
      },
      updateAvailable: function() {
          this.dupdateAvailable = this.updateAvailable;
      }
  }
}
</script>