<template>
  <div id="app">
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css">
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css">
    <navigation-bar v-bind:version="version" v-bind:versionIsLoading="versionIsLoading" v-bind:updateAvailable="updateAvailable" />
    <div class="m-2"></div>
    <div id="wrapper">
      <router-view/>
    </div>
    <b-loading :is-full-page="true" v-model="init" :can-cancel="false">
      <b-icon
          class="mr-2"
          pack="fas"
          icon="sync-alt"
          custom-class="fa-spin"
          >
      </b-icon>
      Initialisierung...
    </b-loading>
  </div>
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue';
import API from '@/api/API';
import semver from 'semver';

export default {
  name: 'Home',
  components: {
    NavigationBar
  },
  data() {
    return {
      versionIsLoading: true,
      version: '',
      updateAvailable: false,
      init: false
    }
  },
  methods: {
    loadVersion() {
      if (localStorage.version) {
        this.version = localStorage.version;
        API.getVersion().then(v => {
          this.versionIsLoading = false;
          if(semver.lt(this.version, v.data.version)) {
            this.updateAvailable = true;
          }
        });
      } else {
          this.init = true;
          API.getVersion().then(v => {
            API.getData().then(x => {
                localStorage.setItem('version', v.data.version);
                localStorage.setItem('data', JSON.stringify(x.data.data));
                this.versionIsLoading = false;
                this.version = v.data.version;
                this.init = false;
                this.$root.$emit('dataUpdated', 'new data');
            })
        });
      }
    }
  },
  mounted() {
    this.loadVersion();
  }
}
</script>

<style lang="scss">
html,
body {
  box-sizing: border-box;
  min-height: 100vh;
  position: relative;
  background-color: rgb(31,35,48);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

$box-background-color: #2c3e50;
$box-color: rgb(0,171,250);

$input-background-color: #2c3e50;
$label-color: #fff;

$navbar-background-color: #2c3e50;
$navbar-item-color: #fff;

$input-color: #fff;
$input-focus-border-color: #00d1b2;
$input-border-color: #dbdbdb;
$input-icon-color: #dbdbdb;
$input-icon-active-color: #fff;

$navbar-item-hover-background-color: #2c3e50;

$link: #42b983;
$link-invert: #42b983;
$link-focus-border: #42b983;
$link-hover: #42b980;

$loading-background: #50055E66;

@import '~bulma/sass/utilities/_all';
@import '~bulma';
@import '~buefy/src/scss/buefy';
</style>
