import httpClient from './httpClient';

class API {
    static getVersion() {
        return httpClient.get('version');
    }

    static getData() {
        return httpClient.get('data');
    }
}

export default API;